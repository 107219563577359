<script>
    export let nama_foto, baseURL;   
</script>
<svelte:head> 
</svelte:head>             
<img
    src="{baseURL+"/images/banner_slider/"+nama_foto}"
    alt="{nama_foto}"
    class="img-thumbnail"
/>   
