<script>
    import jQuery from "jquery";
    let token = document.head.querySelector('meta[name="csrf-token"]');
    let csrfToken = token.content; 
    let value;
    let response;
    let onChange = () => {};
    export let data_album, baseURL;
    $: onChange(value);
    let tanggal_album = data_album.tanggal_album;

    async function handleSubmit(event) {
        const formData = new FormData(event.target);
        const dataForm = {};
        for (const [key, value] of formData.entries()) {
            dataForm[key] = value;
            dataForm['id_album'] = data_album.id_album;
        }
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": csrfToken,
        };
        try {
            const res = await fetch(
                baseURL+ "/album-foto/update",
                {
                    headers: headers,
                    method: "POST",
                    body: JSON.stringify({
                        dataForm,
                    }),
                }
            );
            response = await res.json();
            console.log(response);

        } catch (error) {
            Swal.fire({
                title: "Gagal",
                icon: "error",
                text: error,
                confirmButtonText: "OK",
            }).then(function () {
              //  window.location.reload();
            });
        }
        if (response.status === true) {
            Swal.fire({
                title: "Berhasil",
                icon: "success",
                text: response.message,
                confirmButtonText: "OK",
            }).then(function () {
                window.location.replace("/album-foto");
            });
        } else {
            Swal.fire({
                title: "Gagal",
                text: response.message,
                icon: "error",
                confirmButtonText: "OK",
            }).then(function () {
                // window.location.reload();
            });
        }
              
    }
    
   
</script>

<h4>Edit Album</h4>
<form method="post" on:submit|preventDefault={handleSubmit}>
   
    <div class="mb-3 mt-3">
        <label for="exampleFormControlInput1" class="form-label"
            >Nama Album</label
        >
        <input        
            type="text"
            name="nama_album"
            id="nama_album"
            class="form-control"                                   
            required
            oninvalid="this.setCustomValidity('Data tidak boleh kosong')"
            oninput="setCustomValidity('')"
            value={data_album.nama_album}
        />
    </div>
    <div class="mb-3 mt-3">
        <label for="exampleFormControlInput1" class="form-label"
            >Tanggal</label
        >
        <input        
            type="date"
            name="tanggal_album"
            id="tanggal_album"
            class="form-control"                                   
            required
            oninvalid="this.setCustomValidity('Data tidak boleh kosong')"
            oninput="setCustomValidity('')"   
            bind:value={tanggal_album}        
        />
    </div>
   
    <div class="buttons">
        <button type="submit" class="btn btn-md btn-primary">
           Simpan
        </button>
    </div>
</form>

<style>
    .buttons {
        display: flex;
        justify-content: space-between;
    }
</style>
