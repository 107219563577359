<script>
    import jQuery from "jquery";
    import {LoadingOverlay}  from 'gasparesganga-jquery-loading-overlay';
    import Swal from "sweetalert2";
    import { paginate, LightPaginationNav } from 'svelte-paginate'
    export let baseURL, berita_terbaru, kategori_all,terpopuler_all, hasil_pencarian;
    let token = document.head.querySelector('meta[name="csrf-token"]');
    let csrfToken = token.content; 
    let response;
    let isLoading = false;
    let keyword;
    let items = hasil_pencarian;
    let currentPage = 1
    let pageSize = 2
    $: paginatedItems = paginate({ items, pageSize, currentPage })

    async function handleSubmit(event) {
        var query = {
            keyword: keyword,
        };
        var url = "/berita/pencarian?" + jQuery.param(query);
        window.location = url;
    }

</script>
<svelte:head>
    <title>Pencarian Berita</title>
</svelte:head>
<main>
   <!-- Start main-content -->
   <div class="main-content">
    <!-- Section: inner-header -->
    <section class="inner-header divider  layer-overlay overlay-white-3" data-bg-img="{baseURL+'/images/bg-header.jpg'}">
      <div class="container pt-60 pb-60">
        <!-- Section Content -->
        <div class="section-content">
          <div class="row">
            <div class="col-md-12 text-center">
              <h2 class="title">Hasil Pencarian Berita</h2>
              <ol class="breadcrumb text-center text-black mt-10">
                <li><a href="/">Beranda</a></li>
                <!-- svelte-ignore a11y-invalid-attribute -->
                <li><a href="#">Informasi</a></li>
                <li class="active text-theme-colored">Berita</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Section: Blog -->
    <section>
      <div class="container mt-30 mb-30 pt-30 pb-30"> 
        <div class="row multi-row-clearfix">
          <div class="col-md-9">
            <div class="blog-posts">
              {#if hasil_pencarian != null}
                {#each paginatedItems as berita}
                <article class="post clearfix mb-30 bg-lighter">
                    <div class="entry-header">
                      <div class="post-thumb thumb"> 
                        <img src="{baseURL + '/images/gambar_utama_berita/'+berita.gambar_utama}" alt="" class="img-responsive img-fullwidth"> 
                      </div>
                    </div>
                    <div class="entry-content border-1px p-20 pr-10">
                      <div class="entry-meta media mt-0 no-bg no-border">
                        <div class="entry-date media-left flip text-center">
                            <ul>
                              <li class="font-16 font-weight-600 border-bottom bg-white-f1 pt-5 pr-15 pb-5 pl-15">{berita.tanggal}</li>
                              <li class="font-12 text-white text-uppercase bg-theme-colored pt-5 pr-15 pb-5 pl-15">{berita.bulan} {berita.tahun}</li>
                            </ul>
                          </div>
                        <div class="media-body pl-15">
                            <div class="event-content pull-left flip">
                                <h4 class="entry-title text-white text-uppercase m-0 mt-5"><a href="{'/berita/detail/'+berita.slug_berita}">{berita.judul_berita}</a></h4>
                                <span class="mb-10 text-gray-darkgray mr-10 font-13"><i class="fa fa-user mr-5 text-theme-colored"></i>{berita.author}</span>
                                <span class="mb-10 text-gray-darkgray mr-10 font-13"><i class="fa fa-eye mr-5 text-theme-colored"></i> {berita.jumlah_view}x Dilihat</span>                       
                               </div>
                        </div>
                      </div>
                      <p class="mt-10">{@html berita.excerpt}</p>
                      <a href="{'/berita/detail/'+berita.slug_berita}">Selengkapnya →</a>
                      <div class="clearfix"></div>
                    </div>
                  </article>                 
                {/each}
                <div class="col-md-12">
                    <nav>
                        <ul class="pagination theme-colored">
                          <LightPaginationNav
                          totalItems="{items.length}"
                          pageSize="{pageSize}"
                          currentPage="{currentPage}"
                          limit="{1}"
                          showStepOptions="{true}"
                          on:setPage="{(e) => currentPage = e.detail.page}"
                          />     
                      </nav>
                  </div>
                {:else}
                <div class="row">
                  <div class="col-md-8 col-md-offset-2">
                    <h1 class="font-150 text-theme-colored mt-0 mb-0"><i class="fa fa-map-signs text-gray-silver"></i>404!</h1>
                    <h2 class="mt-0">Oops! Tidak ditemukan</h2>
                    <p>Tidak ada berita yang cocok dengan pencarian Anda</p>
                  </div>
                </div>
                {/if}          
            </div>
          </div>
          <div class="col-md-3">
            <div class="sidebar sidebar-left mt-sm-30">
              <div class="widget hidden-xs hidden-sm">
                <h5 class="widget-title line-bottom">Pencarian</h5>
                <div class="search-form">
                    <form  method="post" on:submit|preventDefault={handleSubmit}>
                        <div class="input-group">
                          <input type="text" placeholder="Cari judul berita..." class="form-control search-input "  bind:value={keyword}>
                          <span class="input-group-btn">
                          <button type="submit" class="btn search-button"><i class="fa fa-search"></i></button>
                          </span>
                        </div>
                      </form>
                </div>
              </div>
              <div class="widget">
                <h5 class="widget-title line-bottom">Kategori</h5>
                <div class="categories">
                  <ul class="list list-border angle-double-right">
                    {#each kategori_all as data_kategori}
                    <li><a href="#">{data_kategori.nama_kategori}</a></li>
                    {/each}
                  </ul>
                </div>
              </div>
              <div class="widget">
                <h5 class="widget-title line-bottom">Terbaru</h5>
                <div class="latest-posts">
                  {#each berita_terbaru as data_berita}
                  <article class="post media-post clearfix pb-0 mb-10">
                    <a class="post-thumb" href="#"><img src="https://placehold.it/75x75" alt=""></a>
                    <div class="post-right">
                      <h5 class="post-title mt-0"><a href="{'/berita/detail/'+data_berita.slug_berita}">{data_berita.judul_berita}</a></h5>
                    </div>
                  </article>
                  {/each}
                </div>
              </div>
              <div class="widget">
                <h5 class="widget-title line-bottom">Terpopuler</h5>
                <div class="latest-posts">
                  {#each terpopuler_all as data_berita}
                  <article class="post media-post clearfix pb-0 mb-10">
                    <a class="post-thumb" href="#"><img src="https://placehold.it/75x75" alt=""></a>
                    <div class="post-right">
                      <h5 class="post-title mt-0"><a href="{'/berita/detail/'+data_berita.slug_berita}">{data_berita.judul_berita}</a></h5>
                    </div>
                  </article>
                  {/each}               
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- end main-content -->
</main>