<script>
    import jQuery from "jquery";
    import Swal from "sweetalert2";
    import InPlaceEdit from "./InPlaceEdit.svelte";
    import { LoadingOverlay } from "gasparesganga-jquery-loading-overlay";

    export let users, baseURL;
    let token = document.head.querySelector('meta[name="csrf-token"]');
    let csrf_token = token.content;
    let avatar, fileinput;
    let responseUpdate;
    let responseUpdatePassword;
    let id_users = users.id_users;
    let nama = users.nama;
    let email = users.email;
    let nomor_identitas = users.nomor_identitas;
    let username = users.username;
    let no_telp = users.no_telp;
    let alamat = users.alamat;
    let password;
    let response;
    let errors;

    const onFileSelected = (e) => {
        let image = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
            avatar = e.target.result;
            upload();
        };
    };
    async function handleSubmit(newValue, fieldName) {
        jQuery.LoadingOverlay("show");

        const dataForm = {};
        dataForm["field_name"] = fieldName;
        dataForm["id_users"] = id_users;
        dataForm["id_data"] = users.id;
        dataForm["value"] = newValue;
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": csrf_token,
        };

        try {
            const resp = await fetch(baseURL + "/pengguna/profile/update", {
                headers: headers,
                method: "POST",
                body: JSON.stringify({
                    dataForm,
                }),
            });
            responseUpdate = await resp.json();
            console.log(responseUpdate);
            jQuery.LoadingOverlay("hide");

            if (responseUpdate.status === true) {
                Swal.fire({
                    title: "Berhasil",
                    icon: "success",
                    text: responseUpdate.message,
                    confirmButtonText: "OK",
                }).then(function () {
                    window.location.replace(
                        "/pengguna/profil/" + users.id_users
                    );
                });
            } else if (responseUpdate.status === false) {
                jQuery.LoadingOverlay("hide");
                Swal.fire({
                    title: "Gagal",
                    text: responseUpdate.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            } else {
                jQuery.LoadingOverlay("hide");
                Swal.fire({
                    title: "Gagal",
                    text: responseUpdate.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        } catch (error) {
            jQuery.LoadingOverlay("hide");
            error;
            Swal.fire({
                title: "Gagal",
                icon: "error",
                text: error,
                confirmButtonText: "OK",
            }).then(function () {});
        }
    }
    async function submitPassword(newPassword) {
        jQuery.LoadingOverlay("show");

        const dataForm = {};
        dataForm["id_users"] = users.id;
        dataForm["new_password"] = newPassword;
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": csrf_token,
        };
        const res = await fetch(baseURL + "/pengguna/profile/update-password", {
            headers: headers,
            method: "POST",
            body: JSON.stringify({
                dataForm,
            }),
        });
        responseUpdatePassword = await res.json();
        console.log(responseUpdatePassword);
        jQuery.LoadingOverlay("hide");

        if (responseUpdatePassword.status === true) {
            Swal.fire({
                title: "Berhasil",
                icon: "success",
                text: responseUpdatePassword.message,
                confirmButtonText: "OK",
            }).then(function () {
                window.location.reload();
            });
        } else {
            jQuery.LoadingOverlay("hide");
            Swal.fire({
                title: "Gagal",
                text: responseUpdatePassword.message,
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    }

    function submit(field) {
        return ({ detail: newValue }) => {
            handleSubmit(newValue, field);
        };
    }
    function generatePassword() {
        var i = 0;
        var numkey = "";
        var randomNumber;

        while (i < 6) {
            randomNumber = (Math.floor(Math.random() * 100) % 94) + 33;

            if (randomNumber >= 33 && randomNumber <= 47) {
                continue;
            }
            if (randomNumber >= 58 && randomNumber <= 90) {
                continue;
            }
            if (randomNumber >= 91 && randomNumber <= 122) {
                continue;
            }
            if (randomNumber >= 123 && randomNumber <= 126) {
                continue;
            }

            i++;
            numkey += String.fromCharCode(randomNumber);
        }
        password = numkey;
    }
    async function upload() {
        jQuery.LoadingOverlay("show");
        const formData = new FormData();
        formData.append("idusers", users.id);
        formData.append("image", jQuery("input[type=file]")[0].files[0]);
        formData.append("_token", csrf_token);
        const headers = {
            headers: [["Content-Type", "multipart/form-data"]],
            "X-CSRF-TOKEN": csrf_token,
        };

        try {
            const resp = await fetch(baseURL + "/pengguna/profile/uploadfoto", {
                headers: headers,
                method: "POST",
                body: formData,
            });
            response = await resp.json();
            jQuery.LoadingOverlay("hide");
            console.log(response);
        } catch (error) {
            jQuery.LoadingOverlay("hide");
            Swal.fire({
                title: "Gagal",
                icon: "error",
                text: error,
                confirmButtonText: "OK",
            }).then(function () {});
        }
        if (response.status === true) {
            Swal.fire({
                title: "Berhasil",
                icon: "success",
                text: response.message,
                confirmButtonText: "OK",
            }).then(function () {
                window.location.reload();
            });
        } else {
            jQuery.LoadingOverlay("hide");
            Swal.fire({
                title: "Gagal",
                text: response.message,
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    }
</script>

<svelte:head>
    <title>Profil</title>
    <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/js/bootstrap.min.js"
        integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
        crossorigin="anonymous"
    ></script>
</svelte:head>
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="card-title mb-4">
                        <div class="d-flex justify-content-start">
                            <div class="image-container">
                                {#if users.foto_profil != "default.png"}
                                    <img
                                        id="imgProfile"
                                        style="width: 150px; height: 150px"
                                        class="img-thumbnail"
                                        src="{baseURL}/images/foto_profil/{users.foto_profil}"
                                        alt="avatar"
                                    />
                                {:else}
                                    <img
                                        id="imgProfile"
                                        style="width: 150px; height: 150px"
                                        class="img-thumbnail"
                                        src="{baseURL}/images/default.png"
                                        alt=""
                                    />
                                {/if}
                                <div class="middle">
                                    <form>
                                        <div
                                            class="file btn btn-md btn-primary"
                                            on:click={() => {
                                                fileinput.click();
                                            }}
                                        >
                                            Ganti Photo
                                            <input
                                                class="btn btn-xs btn-secondary"
                                                type="file"
                                                id="fileUpload"
                                                name="image"
                                                style="display:none"
                                                accept=".jpg, .jpeg, .png"
                                                on:change={(e) =>
                                                    onFileSelected(e)}
                                                bind:this={fileinput}
                                                :multiple="multiple"
                                                :accept="accept"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="userData ml-3">
                                <h2
                                    class="d-block"
                                    style="font-size: 1.5rem; font-weight: bold"
                                >
                                    <h5>{users.nama}</h5>
                                </h2>
                                <h6 class="d-block">
                                    <p>{users.id_users}</p>
                                </h6>
                            </div>
                            <div class="ml-auto">
                                <input
                                    type="button"
                                    class="btn btn-primary d-none"
                                    id="btnDiscard"
                                    value="Discard Changes"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <ul
                                class="nav nav-tabs mb-4"
                                id="myTab"
                                role="tablist"
                            >
                                <li class="nav-item">
                                    <a
                                        class="nav-link active"
                                        id="basicInfo-tab"
                                        data-toggle="tab"
                                        href="#biodata"
                                        role="tab"
                                        aria-controls="basicInfo"
                                        aria-selected="true">Informasi Akun</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        class="nav-link"
                                        id="gantiPassword-tab"
                                        data-toggle="tab"
                                        href="#gantiPassword"
                                        role="tab"
                                        aria-controls="gantiPassword"
                                        aria-selected="false">Ganti Password</a
                                    >
                                </li>
                            </ul>
                            <div class="tab-content ml-1" id="myTabContent">
                                <div
                                    class="tab-pane fade show active"
                                    id="biodata"
                                    role="tabpanel"
                                    aria-labelledby="biodata-tab"
                                >
                                    <div class="row">
                                        <div class="col-sm-3 col-md-2 col-5">
                                            <label
                                                for="name"
                                                style="font-weight:bold;"
                                                >ID Administrator</label
                                            >
                                        </div>
                                        <div class="col-md-8 col-6">
                                            {users.id_users}
                                        </div>
                                    </div>
                                    <hr />

                                    <div class="row">
                                        <div class="col-sm-3 col-md-2 col-5">
                                            <label
                                                for="name"
                                                style="font-weight:bold;"
                                                >Nama Lengkap</label
                                            >
                                        </div>
                                        <div class="col-md-8 col-6">
                                            <InPlaceEdit
                                                bind:value={nama}
                                                on:submit={submit("nama")}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-3 col-md-2 col-5">
                                            <label
                                                for="name"
                                                style="font-weight:bold;"
                                                >Nomor Identitas</label
                                            >
                                        </div>
                                        <div class="col-md-8 col-6">
                                            <InPlaceEdit
                                                bind:value={nomor_identitas}
                                                on:submit={submit(
                                                    "nomor_identitas"
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <hr />

                                    <div class="row">
                                        <div class="col-sm-3 col-md-2 col-5">
                                            <label
                                                for="name"
                                                style="font-weight:bold;"
                                                >Email</label
                                            >
                                        </div>
                                        <div class="col-md-8 col-6">
                                            <InPlaceEdit
                                                bind:value={email}
                                                on:submit={submit("email")}
                                            />
                                        </div>
                                    </div>
                                    <hr />

                                    <div class="row">
                                        <div class="col-sm-3 col-md-2 col-5">
                                            <label
                                                for="name"
                                                style="font-weight:bold;"
                                                >Username</label
                                            >
                                        </div>
                                        <div class="col-md-8 col-6">
                                            <InPlaceEdit
                                                bind:value={username}
                                                on:submit={submit("username")}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-3 col-md-2 col-5">
                                            <label
                                                for="name"
                                                style="font-weight:bold;"
                                                >Nomor Telepon</label
                                            >
                                        </div>
                                        <div class="col-md-8 col-6">
                                            <InPlaceEdit
                                                bind:value={no_telp}
                                                on:submit={submit("no_telp")}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-3 col-md-2 col-5">
                                            <label
                                                for="name"
                                                style="font-weight:bold;"
                                                >Alamat</label
                                            >
                                        </div>
                                        <div class="col-md-8 col-6">
                                            <InPlaceEdit
                                                bind:value={alamat}
                                                on:submit={submit("alamat")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade"
                                    id="gantiPassword"
                                    role="tabpanel"
                                    aria-labelledby="gantiPassword-tab"
                                >
                                    <div class="row">
                                        <div class="col-6">
                                            <label
                                                class="form-label"
                                                for="exampleFormControlInput1"
                                                >Password<span
                                                    class="text-danger">*</span
                                                ></label
                                            >
                                            <div class="input-group mb-3">
                                                <input
                                                    class="form-control"
                                                    id="password"
                                                    name="password"
                                                    type="text"
                                                    bind:value={password}
                                                />
                                                <button
                                                    on:click={generatePassword}
                                                    class="btn btn-secondary"
                                                    type="button"
                                                    >Generate Password</button
                                                >
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-10">
                                                <button
                                                    on:click={submitPassword(
                                                        password
                                                    )}
                                                    type="button"
                                                    class="btn btn-primary"
                                                    >Simpan</button
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
