<script>
    export let data;
</script>

<svelte:head>
    <title>Detail Survey</title>
</svelte:head>
<main>
    <div class="row">
        <div class="card mb-4">
            <div class="card-body">
                <h5 class="card-title mb-4">Detail Survey</h5>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group row">
                            <label for="username" class="col-4 col-form-label"
                                >Nama</label
                            >
                            <div class="col-8">
                                <strong>{data.nama}</strong>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="name" class="col-4 col-form-label"
                                >Email</label
                            >
                            <div class="col-8">
                                <strong>{data.email}</strong>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="text" class="col-4 col-form-label"
                                >Nomor Telepon
                            </label>
                            <div class="col-8">
                                <strong>{data.no_telp}</strong>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="select" class="col-4 col-form-label"
                                >Indikator Pertanyaan</label
                            >
                            <div class="col-8">
                                <strong>Kepuasan</strong>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="select" class="col-4 col-form-label"
                                >Jawaban</label
                            >
                            <div class="col-8">
                                <strong>{data.jawaban}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
