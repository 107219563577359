<script>
    import jQuery from "jquery";
    import { onMount, tick } from "svelte";
    import { writable } from "svelte/store";
    import { DataTable} from 'datatables.net';
    import AddModal from "./Add.svelte";
    import EditModal from "./Edit.svelte";
    import Modal, { bind } from "svelte-simple-modal";    
    export let album_all, baseURL;
    let token = document.head.querySelector('meta[name="csrf-token"]');
    let csrf_token = token.content;
    let response;
    let el;
    let table;
    let rownumber = 1;
    const modal = writable(null);

    const ShowAddModal = () =>
        modal.set(bind(AddModal, { baseURL: baseURL})); 
        
    const ShowEditModal = (data) =>
        modal.set(bind(EditModal, { baseURL: baseURL, data_album: data})); 
       

    onMount(() => {
        table = jQuery(el).DataTable({
            language: {
                search: "",
                searchPlaceholder: "Pencarian",
                paginate: {
                    first: "Awal",
                    last: "Akhir",
                    next: " Selanjutnya &#8594;", // or '→'
                    previous: "&#8592; Sebelumnya", // or '←'
                },
                emptyTable: "Data tidak tersedia",
                info: "Menampilkan _START_ to _END_ dari total _TOTAL_ data",
                infoFiltered: "(filtered from _MAX_ total entries)",
                infoEmpty: "Menampilkan 0 to 0 dari  0 data",
                zeroRecords: "Tidak ada hasil",
                loadingRecords: "Loading...",
                lengthMenu: "Tampilkan _MENU_ data",
            },
            scrollCollapse: true,
        });
    });

    function confirmDelete(id_album) {
        Swal.fire({
            title: "Hapus data",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Batal",
        }).then((result) => {
            if (result.value) {
                destroy(id_album);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Dibatalkan", "Tindakan dibatalkan:)", "error");
            }
        });
    }
    async function destroy(id_album) {
        const formData = { id_album: id_album };
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": csrf_token,
        };
        try {
            const res = await fetch(baseURL+"/album-foto/delete", {
                headers: headers,
                method: "POST",
                body: JSON.stringify({
                    formData,
                }),
            });
        response = await res.json();
        } catch (error) {
            Swal.fire({
                title: "Gagal",
                icon: "error",
                text: error,
                confirmButtonText: "OK",
            }).then(function () {
              //  window.location.reload();
            });
        }
        if (response.status === true) {
            Swal.fire({
                title: "Berhasil",
                icon: "success",
                text: response.message,
                confirmButtonText: "OK",
            }).then(function () {
                window.location.reload();
            });
        } else {
            Swal.fire({
                title: "Gagal",
                icon: "error",
                text: response.message,
                confirmButtonText: "OK",
            }).then(function () {
                window.location.reload();
            });
        }
    }
</script>

<svelte:head>
    <title>Album Foto</title>
    <style>
        .table>:not(caption)>*>* {

            color:white !important;
        }
    </style>
</svelte:head>
<main>
    <div class="row">
        <div class="card mb-4">
            <div class="card-body">
                <h5 class="card-title">Album Foto</h5>
                <div class="row mb-2 mt-3">
                    <div class="col-xs-12">
                        <div class="d-flex justify-content-between">
                            <div>
                                <button
                                type="button"
                                on:click={ShowAddModal}
                                class="btn btn-primary"
                                ><span class="cil-plus btn-icon mr-2" /> Tambah</button
                            >
                             
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table
                    id="example"
                    bind:this={el}
                    class="display table table-hover dt-responsive nowrap"
                  
                >
                        <thead class="tual-color-primary">
                            <tr class="align-middle text-center ">
                                <th scope="col" width="10">No</th>
                                <th scope="col">Tanggal</th>
                                <th scope="col">Nama Album</th>
                                <th scope="col">Slug</th>                               
                                <th scope="col">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>                            
                          {#each album_all as data_album}
                            <tr class="text-center">
                                <td style="color:  #3c4b64 !important;">{rownumber++}</td>   
                                <td style="color:  #3c4b64 !important;">{data_album.tanggal_album}</td>                             
                                <td style="color:  #3c4b64 !important;">{data_album.nama_album}</td>
                                <td style="color:  #3c4b64 !important;">{data_album.slug_album}</td>
                                <td>
                                    <div class="btn-group">   
                                        <button
                                            on:click={ShowEditModal(
                                                data_album
                                            )}
                                            type="button"
                                            class="btn btn-warning btn-sm"
                                            ><i
                                                class="cil-pencil text-white"
                                            /></button
                                        >                 
                                        <button    
                                        on:click={confirmDelete(
                                            data_album.id_album
                                        )}                  
                                            type="button"
                                            class="btn btn-danger btn-sm"
                                            ><i
                                                class="cil-trash text-white"
                                            /></button
                                        >
                                    </div>
                                </td>
                            </tr>
                         {/each}                                                 
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</main>
<Modal show={$modal} />
