<script>
    import jQuery from "jquery";
    import {LoadingOverlay}  from 'gasparesganga-jquery-loading-overlay';
    import Swal from "sweetalert2";
    export let baseURL, data_fasilitas, fasilitas_pelayanan, dokter_all;
    let token = document.head.querySelector('meta[name="csrf-token"]');
    let csrfToken = token.content; 
    let response;
    let isLoading = false;

</script>
<svelte:head>
    <title>Fasilitas Pelayanan</title>
</svelte:head>
<main>
  <!-- Start main-content -->
  <div class="main-content">
    <!-- Section: inner-header -->
    <section class="inner-header divider parallax layer-overlay overlay-white-8" data-bg-img="{baseURL+'/images/bg-header.jpg'}">
      <div class="container pt-60 pb-60">
        <!-- Section Content -->
        <div class="section-content">
          <div class="row">
            <div class="col-md-12 text-center">
              <h2 class="title">Detail Pelayanan</h2>
              <ol class="breadcrumb text-center text-black mt-10">
                <li><a href="#">Beranda</a></li>
                <li><a href="#">Fasilitas Pelayanan</a></li>
                <li class="active text-theme-colored">Detail</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Section: Service details -->
    <section>
      <div class="container">
        <div class="section-content">
          <div class="row">
            <div class="col-xs-12 col-sm-8 col-md-8 pull-left flip pb-20">
              <div class="row">
                <div class="col-md-12">
                  <img alt="" src="{baseURL+"/images/fasilitas_pelayanan/"+data_fasilitas.foto}" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h2>{data_fasilitas.nama_fasilitas}</h2>
                  <p>{@html data_fasilitas.keterangan}</p>
                </div>
                {#if data_fasilitas.file !== '' && data_fasilitas.file !== null}
                    <div class="col-md-12">
                        <a href="{baseURL+"/files/fasilitas_pelayanan/"+data_fasilitas.file}" target="_blank" class="btn btn-dark btn-theme-colored">
                          <i class="fa fa-file-pdf-o"></i>
                          Alur pelayanan
                        </a>
                    </div>
                {/if}
              </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 sidebar pull-right flip">
              <div class="widget">
                <h3 class="line-bottom mt-0">Pelayanan Lainnya</h3>
                <div class="categories">
                  <ul class="list angle-double-right list-border">
                    {#each fasilitas_pelayanan as data_fasilitas_pelayanan}
                        <li><a href="{baseURL+"/fasilitas-pelayanan/detail/"+data_fasilitas_pelayanan.id}">{data_fasilitas_pelayanan.nama_fasilitas}</a></li>
                    {/each}
                  </ul>
                </div>
              </div>
              <div class="widget">
                <div class="bg-img-box maxwidth400 border-10px p-20">
                  <h5><i class="fa fa-clock-o text-theme-colored"></i> Jam Buka Fasilitas Pelayanan</h5>
                  <div class="opening-hours">
                    <ul class="list-unstyled">
                      <li class="clearfix"> <span> Senin </span>
                        <div class="value"> {data_fasilitas.jam_buka_senin != null && data_fasilitas.jam_buka_senin != '' ? data_fasilitas.jam_buka_senin : ''} </div>
                      </li>
                      <li class="clearfix"> <span> Selasa </span>
                        <div class="value"> {data_fasilitas.jam_buka_selasa != null && data_fasilitas.jam_buka_selasa != '' ? data_fasilitas.jam_buka_selasa : ''} </div>
                      </li>
                      <li class="clearfix"> <span> Rabu </span>
                        <div class="value"> {data_fasilitas.jam_buka_rabu != null && data_fasilitas.jam_buka_rabu != '' ? data_fasilitas.jam_buka_rabu : ''} </div>
                      </li>
                      <li class="clearfix"> <span> Kamis </span>
                        <div class="value"> {data_fasilitas.jam_buka_kamis != null && data_fasilitas.jam_buka_kamis != '' ? data_fasilitas.jam_buka_kamis : ''} </div>
                      </li>
                      <li class="clearfix"> <span> Jumat </span>
                        <div class="value"> {data_fasilitas.jam_buka_jumat != null && data_fasilitas.jam_buka_jumat != '' ? data_fasilitas.jam_buka_jumat : ''} </div>
                      </li>
                      <li class="clearfix"> <span> Sabtu </span>
                        <div class="value"> {data_fasilitas.jam_buka_sabtu != null && data_fasilitas.jam_buka_sabtu != '' ? data_fasilitas.jam_buka_sabtu : ''} </div>
                      </li>
                      <li class="clearfix"> <span> Minggu </span>
                        <div class="value"> {data_fasilitas.jam_buka_minggu != null && data_fasilitas.jam_buka_minggu != '' ? data_fasilitas.jam_buka_minggu : ''} </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Section: Doctors -->
    <section class="bg-lighter">
        <div class="container">
          <div class="section-title mb-0">
            <div class="row">
              <div class="col-md-12">
               <h3 class="title mt-0 line-bottom">Dokter Spesialis</h3>
              </div>
            </div>
          </div>
          <div class="section-content">
            <div class="row">
              <div class="col-md-12">
                <div class="owl-carousel-4col" data-nav="true">
                  {#each dokter_all as data_dokter_all}
                  <div class="item">
                    <div class="team-member maxwidth400">
                      <div class="thumb"><img alt="" src="{baseURL+"/images/foto_pegawai/"+data_dokter_all.foto}" style="width: 250px;height:250px"></div>
                      <div class="info">
                        <h3 class="name"><a href="{baseURL+"/dokter/detail/"+data_dokter_all.nip}">{data_dokter_all.nama}</a></h3>
                        <h5 class="occupation text-gray">{data_dokter_all.keahlian}</h5>
                        <ul class="styled-icons icon-theme-colored icon-circled icon-dark icon-sm mt-10 mb-0">
                          <li><a href="{data_dokter_all.url_facebook}"><i class="fa fa-facebook"></i></a></li>
                          <li><a href="{data_dokter_all.url_twitter}"><i class="fa fa-twitter"></i></a></li>
                          <li><a href="{data_dokter_all.url_instagram}"><i class="fa fa-instagram"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/each}
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  </div>
  <!-- end main-content -->
</main>
