<script>
    import jQuery from "jquery";
    import {LoadingOverlay}  from 'gasparesganga-jquery-loading-overlay';
    import Swal from "sweetalert2";
    export let baseURL, data_berita, berita_terbaru_all, kategori_all,terpopuler_all, deskripsi_singkat, social_media, logo_instansi;
    let token = document.head.querySelector('meta[name="csrf-token"]');
    let csrfToken = token.content; 
    let response;
    let isLoading = false;
    let judul_berita =  data_berita.judul_berita;

</script>
<svelte:head>
    <title>{judul_berita}</title>
</svelte:head>
<main>
    <!-- Start main-content -->
  <div class="main-content">
    <!-- Section: inner-header -->
    <section class="inner-header divider  layer-overlay overlay-white-3" data-bg-img="{baseURL+'/images/bg-header.jpg'}">
      <div class="container pt-60 pb-60">
        <!-- Section Content -->
        <div class="section-content">
          <div class="row">
            <div class="col-md-12 text-center">
              <h2 class="title">{judul_berita}</h2>
              <ol class="breadcrumb text-center text-black mt-10">
                <!-- svelte-ignore a11y-invalid-attribute -->
                <li><a href="#">Beranda</a></li>
                <li><a href="#">Informasi</a></li>
                <li><a href="#">Berita</a></li>
                <li class="active text-theme-colored">Detail</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Section: Blog -->
    <section>
      <div class="container mt-30 mb-30 pt-30 pb-30">
        <div class="row">          
          <div class="col-xs-12 col-sm-12 hidden-md hidden-lg">
            <div class="widget">
              <h5 class="widget-title line-bottom">Pencarian</h5>
              <div class="search-form">
                <form>
                  <div class="input-group">
                    <input type="text" placeholder="Ketik pencarian anda" class="form-control search-input">
                    <span class="input-group-btn">
                    <button type="submit" class="btn search-button"><i class="fa fa-search"></i></button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row multi-row-clearfix">
          <div class="col-md-9">
            <div class="blog-posts single-post">
              <article class="post clearfix mb-0">
                <div class="entry-header">
                  <div class="post-thumb thumb"> <img src="{baseURL + '/images/gambar_utama_berita/'+data_berita.gambar_utama}" alt="" class="img-responsive img-fullwidth"> </div>
                </div>
                <div class="entry-content">
                  <div class="entry-meta media no-bg no-border mt-15 pb-20">
                    <div class="entry-date media-left flip text-center">
                      <ul>
                        <li class="font-16 font-weight-600 border-bottom bg-white-f1 pt-5 pr-15 pb-5 pl-15">{data_berita.tanggal}</li>
                        <li class="font-12 text-white text-uppercase bg-theme-colored pt-5 pr-15 pb-5 pl-15">{data_berita.bulan} {data_berita.tahun}</li>
                      </ul>
                    </div>
                    <div class="media-body pl-15">
                      <div class="event-content pull-left flip">
                        <h3 class="entry-title text-white text-uppercase pt-0 mt-0"><a href="{'/berita/detail/'+data_berita.slug_berita}">{data_berita.judul_berita}</a></h3>
                        <span class="mb-10 text-gray-darkgray mr-10 font-13"><i class="fa fa-user mr-5 text-theme-colored"></i>{data_berita.author}</span>
                        <span class="mb-10 text-gray-darkgray mr-10 font-13"><i class="fa fa-eye mr-5 text-theme-colored"></i> {data_berita.jumlah_view}x Dilihat</span>
                      </div>
                    </div>
                  </div>
                  <p class="mb-15">
                    {@html data_berita.isi_berita}
                  </p>        
                  <div class="mt-30 mb-0">
                    Author  : {data_berita.author}<br/>
                    Dipublikasikan pada : {data_berita.tanggal_publish}
                  </div>                
                  <div class="mt-30 mb-0">
                    <h5 class="pull-left flip mt-10 mr-20 text-theme-colored">Bagikan:</h5>
                    <ul class="styled-icons icon-circled m-0">
                      <li><a href="https://facebook.com/sharer.php?u={baseURL+'/berita/detail/'+data_berita.slug_berita}" data-bg-color="#3A5795"><i class="fa fa-facebook text-white"></i></a></li>
                      <li><a href="#" data-bg-color="#55ACEE"><i class="fa fa-twitter text-white"></i></a></li>
                      <li><a href="https://api.whatsapp.com/send?text={baseURL+'/berita/detail/'+ data_berita.slug_berita}" data-bg-color="#25D366"><i class="fa fa-whatsapp text-white"></i></a></li>
                    </ul>
                  </div>
                </div>
              </article>
           
              <div class="author-details media-post">
                <a href="#" class="post-thumb mb-0 pull-left flip pr-20"><img style="width: 75px; height: 75px; border-radius: 50%; cursor: pointer;" alt="" src="{baseURL+'/images/profil_instansi/'+logo_instansi}"></a>
                <div class="post-right">
                  <h5 class="post-title mt-0 mb-0"><a href="/tentang-kami" class="font-18">RSUD Maren Hi. Noho Renuat</a></h5>
                  {deskripsi_singkat}    
                  <ul class="styled-icons square-sm m-0">
                    <li><a href="{social_media.url_facebook}"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="{social_media.url_twitter}"><i class="fa fa-twitter"></i></a></li>
                    <li><a href="{social_media.url_instagram}"><i class="fa fa-instagram"></i></a></li>
                  </ul>
                </div>
                <div class="clearfix"></div>
              </div>          
             
            </div>
          </div>
          <div class="col-md-3">
            <div class="sidebar sidebar-left mt-sm-30">
              <div class="widget hidden-xs hidden-sm">
                <h5 class="widget-title line-bottom">Pencarian</h5>
                <div class="search-form">
                  <form>
                    <div class="input-group">
                      <input type="text" placeholder="Ketik pencarian anda" class="form-control search-input">
                      <span class="input-group-btn">
                      <button type="submit" class="btn search-button"><i class="fa fa-search"></i></button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="widget">
                <h5 class="widget-title line-bottom">Kategori</h5>
                <div class="categories">
                  <ul class="list list-border angle-double-right">
                    {#each kategori_all as data_kategori}
                    <li><a href="#">{data_kategori.nama_kategori}</a></li>
                    {/each}
                  </ul>
                </div>
              </div>
              <div class="widget">
                <h5 class="widget-title line-bottom">Terbaru</h5>
                <div class="latest-posts">
                  {#each berita_terbaru_all as data_berita_terbaru}
                  <article class="post media-post clearfix pb-0 mb-10">
                    <a class="post-thumb" href="{baseURL+'/berita/detail/'+data_berita_terbaru.slug_berita}"><img src="{baseURL + '/images/gambar_utama_berita/'+data_berita_terbaru.gambar_utama}" alt=""  style="width:75px;height:75px;"></a>
                    <div class="post-right">
                      <h5 class="post-title mt-0"><a href="{baseURL+'/berita/detail/'+data_berita_terbaru.slug_berita}">{data_berita_terbaru.judul_berita}</a></h5>
                    </div>
                  </article>
                  {/each}
                </div>
              </div>              
              <div class="widget">
                <h5 class="widget-title line-bottom">Terpopuler</h5>
                <div class="latest-posts">
                  {#each terpopuler_all as data_berita_terpopuler}
                  <article class="post media-post clearfix pb-0 mb-10">
                    <a class="post-thumb" href="{baseURL+'/berita/detail/'+data_berita_terpopuler.slug_berita}"><img src="{baseURL + '/images/gambar_utama_berita/'+data_berita_terpopuler.gambar_utama}" alt=""  style="width:75px;height:75px;"></a>
                    <div class="post-right">
                      <h5 class="post-title mt-0"><a href="{'/berita/detail/'+data_berita_terpopuler.slug_berita}">{data_berita_terpopuler.judul_berita}</a></h5>
                    </div>
                  </article>
                  {/each}          
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- end main-content -->
</main>